/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

import { rhythm } from "../utils/typography"
import addToMailChimp from 'gatsby-plugin-mailchimp';

async function mailChimpAdd(email, cb) {
  try {
    const {result} = await addToMailChimp(email);
    if (result === 'success') {
      cb({success: true});
    } else {
      cb({error: result})
    }
  } catch({error}) {
    cb({error})
  }
}

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.png/" }) {
        childImageSharp {
          fixed(width: 50, height: 50) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata

  const [email, setEmail] = React.useState('');
  const [mcRes, setMcResponse] = React.useState({});

  return (
    <React.Fragment>
      <div style={{paddingBottom: '40px'}}>
          <h3 style={{color: 'maroon'}}>
            Get tl;dr snippets of future articles 🎉
          </h3>
          {
              mcRes && mcRes.success ? (
                <div style={{color: 'green'}}>thanks</div>
              ) : (
                <div style={{display: 'flex', alignItems: 'center'}}>
                  <div>
                  <input 
                    type="text" 
                    placeholder="your email" 
                    onChange={e => setEmail(e.target.value)} 
                    onKeyUp={e => {if(e.key === 'Enter') mailChimpAdd(email, setMcResponse)}}  
                  />
                  <button 
                    style={{borderRadius: '3px', backgroundColor: 'maroon', color: 'white'}}
                    onClick={() => mailChimpAdd(email, setMcResponse)}
                  >
                    Submit
                  </button>
                  </div>
                </div>
              )
          }
          {
            mcRes && mcRes.error && <div style={{color: 'red'}}>failed :/</div>
          }
        </div>
      <div
        style={{
          display: `flex`,
          marginBottom: rhythm(2.5),
        }}
      >
        <Image
          fixed={data.avatar.childImageSharp.fixed}
          alt={author}
          style={{
            marginRight: rhythm(1 / 2),
            marginBottom: 0,
            minWidth: 50,
            borderRadius: `100%`,
          }}
          imgStyle={{
            borderRadius: `50%`,
          }}
        />
        <p>
          by <a href={`https://twitter.com/${social.twitter}`}>{author}</a> <br /> 
          © {new Date().getFullYear()}
        </p>
      </div>
    </React.Fragment>
  )
}

export default Bio
